import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import avatr from "../../Assets/images/avatar-1.jpg";
import { Avatar, Stack, Box } from "@mui/material";
import { Button } from "react-bootstrap";
import AddNewModal from "./Modal/AddNewModal";
import { showUser, deleteUser } from "../../Api/auth";
import moment from "moment";
import Swal from "sweetalert2";
import { baseURL } from "../../Api/api";

// import { Form, Table } from "react-bootstrap";
// import avatar1 from "../../Assets/images/avatar-1.jpg";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";

export const ManageUser = () => {
  const [modalShow, setModalShow] = useState(false);
  const [searchvalue, setSearchValue] = useState([]);
  const [allusers, setAllusers] = useState([]);
  const [userid, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const GetAllUser = async () => {
    const response = await showUser();
    if (response.remote == "success") {
      setSearchValue(
        response.data.users.users.map((user, idx) => {
          return {
            _id: user._id,
            id: idx + 1,
            Date: moment(user.createdAt).format("DD-MM-YYYY"),
            firstName: user.firstInitial,
            lastName: user.lastInitial,
            gender: user.gender,
            city: user.cityName,
            country: user.countryName,
            phone: user.phone,
            photoURL: user.profileImage,
            status: user.isActive,
            deviceId: user.deviceId,
            Membership: user.planId,
          };
        })
      );
      setAllusers(
        response.data.users.users.map((user, idx) => {
          console.log({ user });
          return {
            _id: user._id,
            id: idx + 1,
            Date: moment(user.createdAt).format("DD-MM-YYYY"),
            firstName: user.firstInitial,
            lastName: user.lastInitial,
            gender: user.gender,
            city: user.cityName,
            country: user.countryName,
            phone: user.email,
            photoURL: user.profileImage,
            status: user.isActive,
            deviceId: user.deviceId,
            Membership: user.planId ? user.planId : "Free Plan",
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    GetAllUser();
  }, []);

  const deleteUsers = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let payload = {
          userId: id,
        };
        const response = await deleteUser(payload);
        if (response.remote == "success") {
          GetAllUser();
        } else {
        }
        Swal.fire("Deleted!", "Your record  has been deleted.", "success");
      }
    });
  };

  const columns = [
    { field: "id", headerName: " User ID", width: 70 },
    { field: "Date", headerName: "Registration Date", width: 130 },
    {
      field: "photoURL",
      headerName: "Avtar",
      width: 90,
      renderCell: (params) => {
        return (
          <Avatar
            style={{ resize: "contain" }}
            src={`${baseURL}/admin/avatars/${params.row.photoURL}`}
          />
        );
      },
    },
    { field: "firstName", headerName: "First Initial", width: 130 },
    { field: "lastName", headerName: "Last Initial", width: 130 },
    { field: "gender", headerName: "Gender", width: 90 },
    { field: "city", headerName: "City", width: 100 },
    { field: "country", headerName: "Country", width: 100 },
    { field: "phone", headerName: "Email", type: "string", width: 220 },

    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => {
        return (
          <div
            className={`cellWithStatus ${
              params.row.status ? "active" : "inactive"
            }`}
          >
            {params.row.status ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    // { field: "joindate", headerName: "join Date", width: 130 },

    { field: "Membership", headerName: "Membership", width: 130 },
    // {
    //   field: "TimeFlagged",
    //   headerName: "Time-Flagged",
    //   type: "number",
    //   width: 130,
    // },
    // {
    //   field: "JoinedGroupHistrory",
    //   headerName: "Joined-Group-Histrory",
    //   width: 200,
    // },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   width: 90,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
    // { field: "Address", headerName: "Address", width: 130 },
    {
      field: "action",
      headerName: "Action",
      width: 130,

      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                selectUser(params.row.deviceId);
              }}
            >
              View
            </div>

            <div
              className="deleteButton"
              onClick={() => deleteUsers(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const selectUser = (deviceId) => {
    setUserId(deviceId);

    setTimeout(() => {
      setModalShow(true);
    }, 1000);
  };

  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setAllusers(filter);
  };

  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={allusers}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <AddNewModal
        show={modalShow}
        userid={userid}
        onHide={() => setModalShow(false)}
        updateUserDetail={() => GetAllUser()}
      />
    </>
  );
};

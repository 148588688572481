import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import Addcountry from "./Modal/Addcountry";
import { getcountrys, deletecountry, updatecountry } from "../../Api/auth";
import moment from "moment";
import Swal from "sweetalert2";
import { Stack, Box } from "@mui/material";

const Country = () => {
  const [modalShow, setModalShow] = useState(false);
  const [allcountry, setAllcountry] = useState([]);
  const [countryid, setCountryId] = useState("1");
  const [searchvalue, setSearchValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getcountry = async () => {
    const response = await getcountrys();
    if (response.remote == "success") {
      setSearchValue(
        response.data.country.map((country, idx) => {
          return {
            _id: country._id,
            id: idx + 1,
            date: country.createdAt,
            country: country.name,
            status: country.status,
            flag: country.emoji,
            iso: country.iso2,
            phonecode: country.phone_code,
          };
        })
      );
      setAllcountry(
        response.data.country.map((country, idx) => {
          return {
            _id: country._id,
            id: idx + 1,
            date: moment(country.createdAt).format("DD-MM-YYYY"),
            country: country.name,
            status: country.status,
            flag: country.emoji,
            iso: country.iso2,
            phonecode: country.phone_code,
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getcountry();
  }, []);

  const deletecoutry = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletecountry(id);
        if (response.remote == "success") {
          getcountry();
        } else {
          // setErrors(response.errors.errors);
          console.log(response.errors);
          // setIsLoading(false);
        }
        Swal.fire("Deleted!", "Your record  has been deleted.", "success");
      }
    });
  };

  const updatCountry = async (id, status) => {
    let payload = {
      status: status === true ? false : true,
      countryId: id,
    };

    const response = await updatecountry(payload);
    if (response.remote == "success") {
      getcountry();
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
    }
  };

  const columns = [
    { field: "id", headerName: "Id", width: 70 },
    { field: "date", headerName: "Date", width: 130 },
    { field: "country", headerName: "Country Name", width: 200 },
    { field: "flag", headerName: "Flag", width: 200 },
    { field: "iso", headerName: "Iso", width: 200 },
    { field: "phonecode", headerName: "Phone Code", width: 200 },

    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div
            className={`cellWithStatus ${
              params.row.status ? "active" : "inactive"
            }`}
            onClick={() => updatCountry(params.row._id, params.row.status)}
          >
            {params.row.status ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <div
              className="viewButton"
              // onClick={() => (setCountryId(params.row._id), setModalShow(true))}
            >
              Edit
            </div> */}

            <div
              className="deleteButton"
              onClick={() => deletecoutry(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setAllcountry(filter);
  };

  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
            <Button onClick={() => setModalShow(true)}>Add Country</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={allcountry}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <Addcountry
        getcountry={getcountry}
        countryid={countryid}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Country;
